import React from 'react'
import Box from '@mui/system/Box';
import 'atropos/atropos.css'
import Atropos from "atropos/react";

import pngTreeCameraRawBeige from "../../images/tree-h348-best/tree-cameraRaw-beige-h348.png"
import pngTreeCameraRawBurn from "../../images/tree-h348-best/tree-cameraRaw-burn-h348.png"
import pngTreeCameraRawHardMix from "../../images/tree-h348-best/tree-cameraRaw-hardMix-h348.png"
import pngTreeCameraRawHu from "../../images/tree-h348-best/tree-cameraRaw-hu-h348.png"
import pngTreeCameraRawTyrkys from "../../images/tree-h348-best/tree-cameraRaw-tyrkys-h348.png"
import pngTreeCameraRawVividC from "../../images/tree-h348-best/tree-cameraRaw-vividC-h348.png"
import pngTreeDouble from "../../images/tree-h348-best/tree-double-h348.png"


// const defaultAtroposOptions = {
//     component: 'div', //	Atropos main component tag
//     // eventsEl:	HTMLElement | string		Pointer events target. If not specified event handlers will be attached to main container (el)
//     activeOffset: 50, // Main container offset when activated (on hover), basically means translateZ value
//     alwaysActive: false,
//     duration: 1300, // Transition duration when activated, leave and and during rotate (in ms)
//     rotate: true, // whether to rotate container on pointer move (when activated)
//     rotateTouch: true, // whether to rotate container on touch move (when activated), rotate must be enabled. Also accepts special values:
//     // scroll-y - will not rotate container with initial vertical touch-move (vertical scrolling), basically will not block page vertical scrolling
//     // scroll-x - will not rotate container with initial horizontal touch-move (horizontal scrolling), basically will not block page horizontal scrolling
//     rotateXMax: 0, // Max rotation along the X-axis (in deg)
//     rotateYMax: 0,	// Max rotation along the Y- axis(in deg)
//     rotateXInvert: false, // false,	// Inverts rotation along the X-axis (in deg)
//     rotateYInvert: false,	// Inverts rotation along the Y-axis (in deg)
//     stretchX: 0,	// Move (translate) atropos container along the X-axis on this value (in percentage). E.g. `50` value will move container +-50% of its width. Only for multiple Atropos, when multiple Atropos uses same parent `eventsEl` container
//     stretchY: 0,	// Move (translate) atropos container along the Y-axis on this value (in percentage). E.g. `50` value will move container +-50% of its height. Only for multiple Atropos, when multiple Atropos uses same parent `eventsEl` container
//     stretchZ: 0,  // Move (translate) atropos container along the Z-axis on this value (in percentage). E.g. `50` value will move container +-50% of its height. Only for multiple Atropos, when multiple Atropos uses same parent `eventsEl` container
//     commonOrigin: true, // false, // true,	//Will dynamically set `transform-origin` for all Atropos components with same parent to same point (usually pointer position). Only for multiple Atropos, when multiple Atropos uses same parent `eventsEl` container
//     shadow: true,	// Enables shadow
//     shadowOffset: 50,	// Shadow offset, basically shadow element's translateZ value
//     shadowScale:  1,	// Shadow scale factor
//     highlight: false, // true,	// Enables highlight
//     // scaleClassName	:		Extra class name on atropos-scale element
//     // rotateClassName	:		Extra class name on atropos-rotate element
//     // innerClassName	:		Extra class name on atropos-inner element
//     // onEnter: function Callback function will be fired when Atropos activated(on hover)
//     // onLeave: function Callback function will be fired when Atropos deactivated(on pointer out)
//     // onRotate: function (x, y)		Callback function will be fired on rotate.As arguments accepts x and y rotation angles
//     // scaleChildren	:		Elements passed here will be added to atropos-scale element
//     // rotateChildren	:		Elements passed here will be added to atropos-rotate root element
// }


// Control Elements Offsets
// Atropos uses special data- attributes to control elements offset (parallax effect):

// data-atropos-offset - controls element offset/translate in percentage.

// For example:
// data-atropos-offset="5" means that element will move 5% of its size on max rotate angles.
// data-atropos-offset="-5" means that element will move -5% of its size on max rotate angles.
// Basically if you want the element to appear closer (be in front of the scene), then this value should be positive. And if you want the element to appear in the distance (be behind the scene), then this value should be negative.

// data-atropos-opacity - controls element opacity.
// For example:
// data-atropos-opacity="0;1" - means element's opacity will change from 0 (on initial state) and up to 1 (on max rotate angles).
// data-atropos-opacity="0.1;0.8" - means element's opacity will change from 0.1 (on initial state) and up to 0.8 (on max rotate angles).
// data-atropos-opacity="1;0" - means element's opacity will change from 1 (on initial state) and down to to 0 (on max rotate angles).



const sxWrap = {
    position: "relative",
    "& .atropos-tree": {
        height: "550px",
        borderStyle: "groove",
        transformOrigin: 'bottom',
        "& .atropos-inner": {
            position: "relative",
            display: 'inline-block',
            borderRadius: '50%',
            perspective: '1000px',
            transformStyle: 'preserve-3d',
            "& .drop": {
                position: 'absolute',

            },
            "& .wrapClay": {
                textAlign: 'center',
                width: 'fit-content',
            },
            "& .svgSep path": {
            }
        },

        "& .layer": {
            position: 'absolute',
            bottom: 0,
            paddingTop: "20px",
            paddingBottom: '0',
            objectFit: 'contain',
            display: 'inline-flex',
            justifyContent: 'center',
            zIndex: '1',
            transformStyle: 'preserve-3d',
            pointerEvents: 'none',
            width: "inherit",
            maxWidth: 'none',
        },
        "& img": {
            position: "relative",
            bottom: 0,
            marginTop: 'auto',
            objectFit: "none",
            display: 'block',
            zIndex: '1',
            transformStyle: 'preserve-3d',
            pointerEvents: 'none',

        },
    }
}

const cssFilters = [
    "none",
    "brightness(0.5)",
    "contrast(0.5)",
    "drop-shadow(2px 4px 6px black)",
    "grayscale(1)",
    "hue-rotate(45deg)",
    "invert(1)",
    "saturate(0.5)",
]

const imgHeight = 350



/** AtroposEffectHillyLandWithTrees
 *used Atropos js library = touch-friendly 3D parallax hover effects
 *3d hover effect with trees
 */
const AtroposEffectHillyLandWithTrees = () => {
    const numberOfHeightLevels = cssFilters.length
    const half = Math.round(numberOfHeightLevels / 2)
    const stepHeight = imgHeight / numberOfHeightLevels / 10
    let alter = 0
    return (
        <Box sx={sxWrap} className="container" >
            <h4 className="toc toc0" id="Hilly-land-with-trees-3d-effect" style={{ marginTop: 0 }} >Stromy - 3d hover efekt</h4>
            <Atropos
                className="atropos-tree"
                activeOffset={-200}
                highlight={true}
                shadow={false}
            >
                {cssFilters.map((filter, i) => {
                    alter = alter + 0
                    const layerBottom = 0
                    const height = imgHeight + (numberOfHeightLevels - i) * stepHeight
                    const rr = (i) % 2
                    const leftPositionPerc = rr === 0 ? `${(numberOfHeightLevels - i) - half}` : `${numberOfHeightLevels + i - half}`
                    const absLeftPosition = Math.abs(leftPositionPerc)
                    const strLeftPosition = `${leftPositionPerc}%`
                    const widthPerc = 120 + absLeftPosition
                    const width = `${widthPerc}%`
                    const svgShift = rr === 0 ? `${(numberOfHeightLevels - i) - half}%` : `${numberOfHeightLevels + i - half}%`
                    const offset = (numberOfHeightLevels - i) * 5
                    return (
                        <div
                            className="layer"
                            data-atropos-offset={offset}
                            data-atropos-opacity="1;0"
                            style={{
                                bottom: `${layerBottom}px`,
                                width: width,
                            }}
                            key={`layer-${i}`}
                        >
                            <img
                                data-atropos-offset={offset}
                                src={pngTreeCameraRawBeige}
                                style={{
                                    filter,
                                    height,
                                }}
                                alt="" />
                            <img
                                data-atropos-offset={offset - 2}
                                src={pngTreeCameraRawBurn}
                                style={{
                                    filter,
                                    height,
                                }}
                                alt="" />
                            <img
                                data-atropos-offset={offset - 4}
                                src={pngTreeCameraRawHardMix}
                                style={{
                                    filter,
                                    height,
                                }}
                                alt="" />
                            <img
                                data-atropos-offset={offset - 6}
                                src={pngTreeCameraRawHu}
                                style={{
                                    filter,
                                    height,
                                }}
                                alt="" />
                            <img
                                data-atropos-offset={offset - 8}
                                src={pngTreeCameraRawTyrkys}
                                style={{
                                    filter,
                                    height,
                                }}
                                alt="" />
                            <img
                                data-atropos-offset={offset - 10}
                                src={pngTreeCameraRawVividC}
                                style={{
                                    filter,
                                    height,
                                }}
                                alt="" />
                            <img
                                data-atropos-offset={offset - 12}
                                src={pngTreeDouble}
                                style={{
                                    filter,
                                    height,
                                }}
                                alt="" />

                        </div>
                    );
                })}
                <img
                    data-atropos-offset="-50"

                    src={pngTreeCameraRawBeige}
                    style={{
                        position: "absolute",
                        left: "40%",
                    }}
                    alt=""

                />
            </Atropos>
        </Box>
    )
}

export default AtroposEffectHillyLandWithTrees